import { I18nProvider } from "@/domain/i18n";
import * as React from "react";

export default function Locale({ children }: { children?: React.ReactNode }) {
  return (
    <I18nProvider locale="de" translations={require("../../public/locales/ch/de.json")}>
      {children}
    </I18nProvider>
  );
}
